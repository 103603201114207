<template>
  <div class="min-h-screen h-full bg-[#F7FBFE]">
    <TsHeader data-testid="header" />

    <div>
      <slot />
    </div>

    <TsProductActionsv2 />

    <TsFooter data-testid="footer" />
  </div>
</template>